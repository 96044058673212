import React from "react"
import { ReactSVG } from "react-svg"
import { Link } from "gatsby"
import vacancyIcon from "@images/vacancy/vacancy-icon.svg"
import "./VacancyCard.scss"

const VacancyCard = ({ vacancy }) => {
  const vacancyPath = `${vacancy.title
    .replace(/\s+/g, "-")
    .replace(/[()]/g, "")
    .replace(/[#.]/g, "")
    .toLowerCase()}-${vacancy.id}`

  return (
    <Link
      className="vacancy-card"
      to={`/careers/vacancy-${vacancyPath}`}
      state={{ vacancy }}
    >
      <div className="vacancy-card__title">
        <h2>{vacancy.title}</h2>
      </div>
      <ReactSVG
        src={vacancyIcon}
        className="vacancy-card__vacancy-icon"
        alt="Vacancy Icon"
      />
    </Link>
  )
}

export default VacancyCard
